import { Site } from "@libry-content/types";
import groq from "groq";
import { GetStaticProps } from "next";
import React from "react";
import Librycontent from "../components/libryContent/LibryContent";
import SEO from "../components/SEO";
import { ResolvedSite, resolveSiteGroqProjecton } from "../components/site/sanityQuery";
import { sanityClient } from "../utils/sanity/client";

const query = groq`*[_type == 'site']{
  ${resolveSiteGroqProjecton}
}`;

interface Props {
  sites: ResolvedSite[];
}

export const getStaticProps: GetStaticProps = async (context) => {
  const sites: Site[] = await sanityClient.fetch(query);

  return {
    props: {
      sites,
    },
    revalidate: 60,
  };
};

function PageWrapper(props: Props) {
  return (
    <>
      <SEO title="Libry Content" description="Nettsidebygger for bibliotekene i Norge" path="/" icon="/favicon.png" />
      <Librycontent sites={props.sites} />
    </>
  );
}

export default PageWrapper;
