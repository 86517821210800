import { withErrorBoundary } from "@biblioteksentralen/js-utils";
import { ts } from "@libry-content/localization";
import { getPlatformUrl, getSiteUrl } from "@libry-content/sanity-common";
import { DecorativeImage, ImageWithMetadata, Site } from "@libry-content/types";
import Head from "next/head";
import React, { useEffect } from "react";
import { loggError } from "../utils/logging";
import { imageUrlBuilder } from "../utils/sanity/client";
import { useSiteContext } from "./layout/SiteContext";

interface Props {
  title: string;
  description: string;
  path: string;
  sanityImage?: DecorativeImage | ImageWithMetadata;
  icon?: string;
}

function getTitle(title: string, site?: Pick<Site, "name">): string {
  if (!site) return title;
  if (!title.length) return ts(site.name) ?? "";
  return `${title} | ${ts(site.name)}`;
}

function SEO(props: Props) {
  const { site } = useSiteContext();

  const iconImg = (site?.logo?.asset && site.logo) || site?.coatOfArms;
  // Image size: https://developers.facebook.com/docs/sharing/webmasters/images/
  const imageUrl = props.sanityImage
    ? imageUrlBuilder(props.sanityImage).size(1200, 630).format("jpg").quality(85).url() ?? ""
    : imageUrlBuilder(iconImg) // Fallback image using logo or coatOfArms, but padded so it can handle some cropping
        .ignoreImageParams()
        .size(1000, 1000)
        .fit("fillmax")
        .pad(250)
        .bg("eee")
        .format("png")
        .quality(85)
        .url() ?? "";

  const canonical = `${site ? getSiteUrl(site) : getPlatformUrl()}${props.path}`;

  const iconUrl =
    imageUrlBuilder(iconImg) // https://sanity-io-land.slack.com/archives/C9Z7RC3V1/p1638963510039100
      .ignoreImageParams()
      .size(100, 100)
      .fit("fillmax")
      .bg("0000")
      .format("png")
      .url() ?? "";

  useEffect(() => {
    if (!/^\//.test(props.path)) {
      loggError(new Error("path må starte med /"));
    }
  }, [props.path]);

  return (
    <Head>
      <title>{getTitle(props.title, site)}</title>
      <link rel="icon" type="image/png" href={props.icon ?? iconUrl} />
      <link rel="canonical" href={canonical} />
      <meta name="description" content={props.description} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:site_name" content={props.title} />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:title" content={props.title} />
      <meta property="twitter:description" content={props.description} />
      <meta property="twitter:image" content={imageUrl} />
      <meta property="og:image" content={imageUrl} />
      <meta property="image" content={imageUrl} />
    </Head>
  );
}

export default withErrorBoundary(SEO, "SEO");
